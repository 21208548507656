import { memo } from 'react'
import { useRouter } from 'next/router'
import styled from 'styled-components'
import { mq, getRelative, createUnderline, getP53, getP26_1, getP20_Mobile } from '@/styles'

// PENDING REAL EMAILS & PHONES for different support countries/regions
const TRANSLATIONS = {
  'en': {
    'any_question': 'Do you have any question?',
    'dont_worry': 'Don\'t worry about it, ',
    'write_us': 'contact us at ',
    'or_call': 'or call',
    'email': 'hello@hellomamma.co.uk',
    'phone_link': '4407946173345',
    'phone': '07946 173345',
  },
  'es': {
    'any_question': '¿Tienes preguntas?',
    'dont_worry': 'Non ti preoccupare, ',
    'write_us': '¡escríbenos!',
    'or_call': 'o llama al',
    'email': 'hola@hellomamma.eu',
    'phone_link': '34933037615',
    'phone': '+34 93 303 76 15',
  },
  'fr': {
    'any_question': 'Des questions?',
    'dont_worry': 'Ne t\'en fais pas, ',
    'write_us': 'écris nous !',
    'or_call': 'ou appelez le',
    'email': 'commandes@hellomamma.eu',
    'phone_link': '0033148904750',
    'phone': '+33 (0) 148 904 750',
  },
  'it': {
    'any_question': 'Hai dubbi?',
    'dont_worry': 'Non ti preoccupare,',
    'write_us': 'scrivici!',
    'or_call': 'o chiamaci al numero',
    'email': 'hola@hellomamma.eu',
    'phone_link': '34933037615',
    'phone': '+34 93 303 76 15',
  }
}

const P = styled.p`
  color: ${({ theme }) => theme.colors.background};
  font-family: ${({ theme }) => theme.fonts.basier};
  ${getP20_Mobile()}
  grid-column: 1 / span 5;
  letter-spacing: -.23px;
  margin-bottom: ${getRelative(50, 'mobile')};

  ${mq.greaterThan('tablet')`
    ${getP26_1()}
    grid-column: 2 / span 4;
    letter-spacing: -.3px;
    margin-bottom: ${getRelative(60, 'desktop')};
    `}

  a {
    ${createUnderline({ reverse: true, color: 'white' })}

    &.en{
      ${createUnderline({ reverse: true, color: 'white' })}
    }
    &.phone {
      ${createUnderline({ color: 'orange', height: 3 })}
    }
  }

  .phone {
    ${getP53()}
    color: ${({ theme }) => theme.colors.orange};
    font-family: ${({ theme }) => theme.fonts.platform};
    display: inline-block;
    margin-top: ${getRelative(10, 'mobile')};

    ${mq.greaterThan('tablet')`
      margin-top: ${getRelative(15, 'desktop')};
    `}
  }
`
export const CallUs = memo(() => {
  const { locale } = useRouter() || { locale: 'es' }

  return (
    <P>
      {TRANSLATIONS[locale].any_question} <br />
      {TRANSLATIONS[locale].dont_worry} {locale === 'en' ? <>
      {TRANSLATIONS[locale].write_us} 
      <a href={ `https://wa.me/${TRANSLATIONS[locale].phone_link}`} className={`${locale}`}>
        { TRANSLATIONS[locale].phone} 
      <span></span>
      </a></>
      :
      <a href={`mailto:${TRANSLATIONS[locale].email}`} className={`${locale}`} >{TRANSLATIONS[locale].write_us}<span></span></a>
      }
    </P>
  )
})
