import { useEffect, useState, useMemo } from 'react'
import { useRouter } from 'next/router'
import { useMount, useModal } from '@/hooks'
import { cookie } from '@dy/commons/utils'
import { MenuSlider, Navbar } from './navbar'
import { Footer } from './footer'
import { AllCartItems, NewCartItems, NewCartItemsContext } from '@dy/commons/components'
import { ModalLanguages, modalLanguagesId, ModalAuth, ModalCustom, ModalNotifyMe, ModalFrNoCart } from '@/modals'
import Susy from './Susy'
import { SlimBanners } from './SlimBanners'
import { CartUrlProvider } from '@dy/commons/hooks'
import { useMediaType } from '@/hooks'

// const PRO = process.env.SITE_ENVIRONMENT !== 'development' && process.env.SITE_ENVIRONMENT !== 'local' && process.env.SITE_ENVIRONMENT !== 'staging'
const STAGINGorPRO = process.env.SITE_ENVIRONMENT !== 'development' && process.env.SITE_ENVIRONMENT !==
'local'
const NEXT_PUBLIC_HOSTNAME_CLIENT = process.env.NEXT_PUBLIC_HOSTNAME_CLIENT

// const redirectDomainsPro = {
//   'FR': 'https://hellomamma.fr',
//   'ES': 'https://hellomamma.es',
//   'GB': 'https://hellomamma.co.uk',
//   'IC': 'https://canarias.hellomamma.es'
// }

// const redirectDomainsStaging = {
//   'FR': 'https://staging.hellomamma.fr',
//   'ES': 'https://staging.hellomamma.es',
//   'GB': 'https://staging.hellomamma.co.uk',
//   'IC': 'https://staging.canarias.hellomamma.es'
// }

const localeByRegion = {
  'ES': ['es'],
  'FR': ['fr'],
  'GB': ['en'],
}

const checkCookiesLang = () => {
  const locale = process.env.NEXT_PUBLIC_LOCALE
  const region = process.env.NEXT_PUBLIC_REGION

  if (!cookie.get('NEXT_LOCALE') || !localeByRegion[region].includes(cookie.get('NEXT_LOCALE'))) {
    if (cookie.get('NEXT_LOCALE')) {
      cookie.removeCookie('NEXT_LOCALE')
    }

    cookie.setObj({ name: 'NEXT_LOCALE', value: locale, domain: NEXT_PUBLIC_HOSTNAME_CLIENT })
  }

  if (!cookie.get('NEXT_REGION') || cookie.get('NEXT_REGION') !== region) {

    if (cookie.get('NEXT_REGION')) {
      cookie.removeCookie('NEXT_REGION')
    }

    cookie.setObj({ name: 'NEXT_REGION', value: region, domain: NEXT_PUBLIC_HOSTNAME_CLIENT })
  }
}

const NEXT_REGION = process.env.NEXT_PUBLIC_REGION
const NEXT_LOCALE = process.env.NEXT_PUBLIC_LOCALE

export const Layout = ({ data, children = null }) => {
  const { header, footer:footerPages, modals:customModals, slim_banners:banners } = data

  const isMount = useMount()
  const { query, pathname } = useRouter() || { locale: NEXT_LOCALE }
  const mediaType = useMediaType()
  const mobile = useMemo(() => mediaType === 'mobile', [mediaType])

  const { open: openCustom } = useModal('modal-custom')
  const { open:openLanguages } = useModal(modalLanguagesId)
  const isVisible = !mobile && (!pathname.includes('/shop/') && !pathname.includes('/brand/') && !pathname.includes('/product/') && !pathname.includes('/account') && !pathname.includes('/search'))

  useEffect(() => {
    const countrySelected = cookie.get('COUNTRY_SELECTED')
    const zone = typeof query.zone === 'string' ? query.zone.toUpperCase() : null

    if(!countrySelected && !zone){
      openLanguages()
    } else if(zone) {
      cookie.set('COUNTRY_SELECTED', NEXT_REGION)
    } else if(countrySelected !== NEXT_REGION) openLanguages()

  }, [query])

  useEffect(() => {
    if(STAGINGorPRO && customModals?.length > 0) {
      setTimeout(() => {
        if(!cookie.get('customModalSeen')) {
          openCustom()
          cookie.set('customModalSeen', 'true', 1)
        }
      }, 5000)
    }
  }, [customModals])

  useEffect(() => {
    checkCookiesLang()
  }, [])


  const [cartItems, setCartItems] = useState([])
  const [showCart, setShowCart] = useState(false)
  
  return (
    <>
      {banners?.length > 0 && <SlimBanners banners={banners} />}
      <NewCartItemsContext.Provider value={{ cartItems, setCartItems }}>
        <CartUrlProvider>
          <Navbar theresPacks={header?.packs} categories={header?.categories} hasBanners={banners?.length > 0} setShowCart={setShowCart} showCart={showCart} />
          {(isVisible && Array.isArray(header?.categories) && header.categories?.length > 0) && <MenuSlider categories={header.categories} displayPacks={header.packs}/>}
            {children}
          <NewCartItems products={cartItems} hasBanners={banners?.length > 0}/>
          <AllCartItems showCart={showCart} setShowCart={setShowCart} hasBanners={banners?.length > 0}/>
        </CartUrlProvider>
      </NewCartItemsContext.Provider>
      {isMount && <Footer key='footer' pages={footerPages}/>}
      {isMount && <Susy hide={STAGINGorPRO ? true : false} columnColor={'rgba(255,0,0,.015)'} columnBorder={'1px solid rgba(255,0,0,.1)'} />}
      <ModalAuth/>
      <ModalNotifyMe/>
      <ModalFrNoCart/>
      {/* <ModalCookies/> */}
      <ModalLanguages customModals={customModals?.length > 0} openCustom={openCustom} />
      {STAGINGorPRO && customModals?.length > 0 && <ModalCustom data={customModals[0]}/>}
    </>
  )
}
