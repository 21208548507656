import { memo, useMemo, useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import styled, { css } from 'styled-components'
import { AnimatePresence, motion } from 'framer-motion'
import { mq, getRelative, getMargin100, getP30, getTransition } from '@/styles'
import { pushAnalyticsEvent } from '@dy/commons/utils'
import { useMediaType } from '@dy/commons/hooks'
import { usePreOrderCart } from '../../hooks'
import { ProductCard, GiftCard } from '@dy/commons/components'
import type { TypeProductCard } from '@/schema'
import { usei18n } from '@dy/commons/i18n'
import { modalFrNoCart, modalNotifyMe } from '@/components'
import { useModal } from '@/hooks'
import { useRouter } from 'next/router'

const PRO = process.env.SITE_ENVIRONMENT !== 'development' && process.env.SITE_ENVIRONMENT !== 'local' && process.env.SITE_ENVIRONMENT !== 'staging' && process.env.SITE_ENVIRONMENT !== 'preview'

const Section = styled(motion.section)<any>`
  grid-column: 1 / span 6;
  grid-row: 3 / span 1;
  margin-bottom: ${getRelative(100, 'mobile')};
  padding-top: ${({ $packsGrid }) => $packsGrid ? 0 : `${getRelative(20, 'mobile')}`};
  transition: 300ms width ease-in-out;

  ${mq.greaterThan<{ $packsGrid: boolean}>('tablet')`
    grid-column: ${({ $packsGrid }) => $packsGrid ? '2 / span 10' : '4 / span 9'};
    grid-row: ${({ $packsGrid }) => $packsGrid ? '1 / span 4' : '3 / span 4'};
    margin-bottom: ${getRelative(100, 'desktop')};
    padding-top: ${({ $packsGrid }) => $packsGrid ? 0 : `${getRelative(60, 'desktop')}`};
  `}
`

const Ul = styled(motion.ul)<any>`
  display: grid;
  grid-gap: ${getRelative(10, 'mobile')};
  grid-template-columns: repeat(2, 1fr);
  padding: 0 ${getRelative(10, 'mobile')};

  ${mq.greaterThan<{ productDetailVisible: boolean, $packsGrid: boolean}>('tablet')`
    grid-gap: ${({ $packsGrid, productDetailVisible }) => ($packsGrid && !productDetailVisible) ? `${getRelative(60, 'desktop')}` : `${getRelative(10, 'desktop')}`};
    grid-template-columns: repeat(${({ $packsGrid }) => $packsGrid ? 3 : 4}, 1fr);
    padding: 0 ${({ productDetailVisible }) => productDetailVisible ? 0 : `${getRelative(10, 'desktop')}`} 0 ${getRelative(10, 'desktop')};
    transform: translateX(${({ theme: { col }, productDetailVisible, $packsGrid }) => productDetailVisible ? `-${getRelative(col.desktop * ($packsGrid ? 1 : 3), 'desktop')}` : 0});
    transition: 200ms width ${({ theme }) => theme.ease}, 200ms transform ${({ theme }) => theme.ease};
    width: ${({ productDetailVisible, $packsGrid }) => 100 - (productDetailVisible ? $packsGrid ? 29.166666666666668 : 22.22222222222222 : 0)}%;
  `}
  li.giftcardleft {
      height:100%;
      grid-column: 1 / span 2;

      ${mq.greaterThan('tablet')`
        grid-column: 1 / span 2;
      `}
  }
  li.giftcardright {
    height:100%;
    grid-column: 1 / span 2;
    ${mq.greaterThan('tablet')`
        grid-column: 3 / span 2;
    `}
  }
  article {
    .product-card-image {
      + div {
        max-width: ${getRelative(188, 'mobile')};

        ${mq.greaterThan<{ productDetailVisible: boolean, $packsGrid: boolean}>('tablet')`
          max-width: ${({ $packsGrid, productDetailVisible }) => getRelative(productDetailVisible ? $packsGrid ? 260 : 195 : $packsGrid ? 360 : 250, 'desktop')};
        `}
      }
    }

    .stacked,
    .product-card_info {
      ${mq.greaterThan<{ productDetailVisible: boolean }>('tablet')`
        ${({ productDetailVisible }) => { if(productDetailVisible) { return css`
          padding-left: ${getRelative(15, 'desktop')};
          padding-right: ${getRelative(15, 'desktop')}
        `}}}
      `}
    }

    &.pack {
      ${({ productDetailVisible }) => { if(!productDetailVisible) { return css`width: auto;`}}}
    }
  }
`

const Li = styled(motion.li)`
  ${mq.greaterThan('tablet')`
    height: ${getRelative(360, 'desktop')};
    width: auto;
  `}

  article {
    margin: 0;
    width: auto;
  }

  article:not(.pack) {
    //width: auto;

    .product-card-image {
      ${mq.greaterThan('tablet')`
        max-width: ${getRelative(260, 'desktop')};
        min-width: ${getRelative(180, 'desktop')};
        width: 100%;
      `}
    }
  }
  .pack {
    .product-card-image {
      ${mq.greaterThan('tablet')`
        max-width: ${getRelative(250, 'desktop')};
        min-width: ${getRelative(180, 'desktop')};
        width: 90%;
      `}
    }
  }
`

const P = styled(motion.p)<any>`
  ${getMargin100()}
  ${getP30()}
  font-weight: 400;
  opacity: .4;
  padding: ${getRelative(40, 'mobile')} ${getRelative(20, 'mobile')};
  text-align: center;

  ${mq.greaterThan('tablet')`
    grid-column: 4 / span 9;
    padding: ${getRelative(40, 'desktop')} 0;
    margin: 0 auto;
    max-width: ${({ theme: { col }}) => getRelative(col.desktop * 6, 'desktop')};
  `}
`

const appendGiftCard = (products) => {
  const giftCardObj = {
    "giftCard": true,
    "bigcommerce_id": null,
    "bigcommerce_slug": null,
    "name": null,
    "slug": null,
    "sku": null,
    "variant": null,
    "pack": false,
    "labels": null,
    "filters": [
      {
        "name": "Marca",
        "param": "marca",
        "type": "multiple",
        "values": [
          {
            "name": "HelloMamma!",
            "value": "hellomamma",
            "description": "",
            "media": null
          }
        ]
      }
    ],
    "brand": null,
    "categories": null
  }

  for (let idx = 0; idx < products.length; idx++) {
    if(idx === 5 && !products[4].giftCard) {
      products.splice(4, 0, giftCardObj)
    } else if(idx === 42 && !products[41].giftCard){
      products.splice(41, 0, giftCardObj)
    } else if(idx === 79 && !products[78].giftCard){
      products.splice(78, 0, giftCardObj)
    } else if(idx === 115 && !products[114].giftCard){
      products.splice(114, 0, giftCardObj)
    }
  }
  return products
}

const getProducts = (products, selectedCard, onClick, ga_list, preOrderCart, triggerModal, triggerFrModal) => products.map((product, idx) => {
  if(product.giftCard) {
    return (
      <Li key={idx} className={idx === 41 ? 'giftcardright' : 'giftcardleft'}>
        <GiftCard data={''} selectedCard={selectedCard} onClick={ e =>{
          e.preventDefault()
          onClick(product,idx)
        }}/>
      </Li>
    )
  } else {
    return (
      <>
        <Li key={idx} >
          <ProductCard isLazy={idx > 5} data={
            {...product, variant: {...product.variant, ga_list, idx: idx + 1}}} selectedCard={selectedCard} onClick={e => {
            e.preventDefault()
            onClick(product, idx)
            }} validForCartType={(preOrderCart && product.is_preorder_only) || (!preOrderCart && !product.is_preorder_only)} preOrderCart={preOrderCart} triggerModal={triggerModal(true, {variant_id: product.variant.bigcommerce_id})} triggerFrModal={triggerFrModal(true)}/>
        </Li>
      </>
    )
  }
})

const variants = {
  active: {
    opactiy: 1,
    transition: {
      ...getTransition()
    }
  },
  inactive: {
    opactiy: 0,
    transition: {
      ...getTransition()
    }
  }
}

interface ISectionProductList {
  products: TypeProductCard[],
  readonly setProductDetailed: any,
  readonly productDetailVisible: boolean,
  displayProductDetail: () => void,
  readonly usualPurchases?: boolean,
  readonly packsGrid?: boolean,
  readonly setSelectedCard?: any,
  readonly selectedCard?: string | number | null,
  readonly ga_list?: {
    id: string,
    name: string
  },
  readonly setShowLargeProductDetail?: any
}

const TRANSLATIONS = {
  'it': {
    'no_filter': 'No hay ningún producto que coincida con los filtros seleccionados',
    'no_products': 'Aún no has comprado ningún producto con nosotros'
  },
  'es': {
    'no_filter': 'No hay ningún producto que coincida con los filtros seleccionados',
    'no_products': 'Aún no has comprado ningún producto con nosotros'
  },
  'en': {
    'no_filter': 'There are no products that match the selected filters',
    'no_products': 'You have not bought any products with us yet'
  },
  'fr': {
    'no_filter': 'Non hai ancora acquistato nessun prodotto con noi',
    'no_products': 'Non hai ancora acquistato nessun prodotto con noi'
  },
}

const scrollToTopGrid = () => {
  if(typeof window === 'object') {
    const main = document.getElementById('main-top')
    if(main) setTimeout(() => { main.scrollIntoView({ behavior: 'smooth' }) }, 300)
  }
}

export const SectionProductList = memo(({ products = [], productDetailVisible, setProductDetailed, displayProductDetail, usualPurchases = false, packsGrid = false, selectedCard, setSelectedCard, ga_list = { id: 'list_grid_shop', name: 'List Shop (grid)' }, setShowLargeProductDetail}:ISectionProductList) => {
  const { asPath, locale } = useRouter() || { asPath: '', locale: 'es' }
  const { layout } = usei18n()
  const mediaType = useMediaType()
  const { preOrderCart } = usePreOrderCart()
  const { triggerModal } = useModal(modalNotifyMe)
  const { triggerModal:triggerFrModal } = useModal(modalFrNoCart)
  const [ref, inView] = useInView({
    rootMargin: '-60px 0px 0px',
    threshold: 1,
    initialInView: true
  })

  const onClick = (product, idx) => {
    if(selectedCard !== product.variant.bigcommerce_id) {
      setSelectedCard && setSelectedCard(product.variant.bigcommerce_id)
      setProductDetailed({
        variant_id: product.variant.bigcommerce_id,
        slug: product.slug,
        ga_list,
        idx: idx + 1
      })

      if(mediaType !== 'mobile' && idx < 4) {
        scrollToTopGrid()
      }

      displayProductDetail()
    }

    if(typeof window === 'object' && window.location.pathname !== `/product/${product.slug[locale]}`) {
      window.history.pushState('', '', `/product/${product.slug[locale]}`)
    }

    pushAnalyticsEvent('select_item', {...product, idx: idx + 1, ga_list, locale })
  }

  const Products = useMemo(() => getProducts(PRO ? products : appendGiftCard(products), selectedCard, onClick, ga_list, preOrderCart, triggerModal, triggerFrModal), [products, selectedCard, preOrderCart, triggerModal, triggerFrModal])

  useEffect(() => {
    setShowLargeProductDetail && setShowLargeProductDetail(!inView)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView])

  return (
    <Section productDetailVisible={productDetailVisible} $packsGrid={packsGrid}>
        <span ref={ref}></span>
        <AnimatePresence exitBeforeEnter>
        {products.length > 0 ?
          <Ul initial={'inactive'} animate={'active'} exit={'inactive'} variants={variants} key={asPath} productDetailVisible={productDetailVisible} $packsGrid={packsGrid}>
            {Products}
          </Ul>
          :
          <P initial={'inactive'} animate={'active'} exit={'inactive'} variants={variants} key={asPath}>
            {!usualPurchases ?
              layout?.shop?.filter?.empty
            :
              TRANSLATIONS[locale].no_products
            }
          </P>
        }
      </AnimatePresence>
    </Section>
  )
})
