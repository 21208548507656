import { memo, Dispatch, SetStateAction, useMemo } from 'react'
import Link from 'next/link'
import styled from 'styled-components'
import { useRouter } from 'next/router'
import { motion, AnimatePresence } from 'framer-motion'
import { mq, getRelative, createUnderline, getP30, getP20_1, getP18_1, getP15_1B, getP18_3, getP12, getP12_1, getP20_4, getP16 } from '@/styles'
import { Dropdown, Price, ImageSlider, RadioCheckBoxes, AddToCart, getPricePerUnit, MaxHeightContainer, FILTER_LABELS, getFilterLabels } from '@dy/commons/components'
import { usei18n } from '@dy/commons/i18n'
import { modalNotifyMe, modalFrNoCart } from '@/components'
import { useModal } from '@/hooks'

type TypeGridMedia = {
  grid: any
}

type TypeProductVariant = {
  bigcommerce_id: number,
  name: string,
  prices: {
    price_inc_tax: number,
    retail_price_inc_tax: number,
    price_ex_tax: number,
    retail_price_ex_tax: number,
  },
  stock: number,
  reference_price: number,
  reference_unit: string,
  media: TypeGridMedia[],
  stock_warning?: boolean
}

interface IDetailHeader {
  data: any,
  labels?: any,
  isPack?: boolean
  currentVariant: TypeProductVariant,
  setCurrentVariant: Dispatch<SetStateAction<TypeProductVariant>>,
  validForCartType?: boolean
  preOrderCart?: boolean,
  preOrderDateMessage?: string
}

const TRANSLATIONS = {
  'en': {
    'brand': 'brand'
  },
  'es': {
    'brand': 'marca'
  },
  'fr': {
    'brand': 'marque'
  },
  'it': {
    'brand': 'marca'
  }
}

const Header = styled.header<any>`
  h1 {
    ${getP30()}
    text-align: left;
    font-family: ${({ theme }) => theme.fonts.basier};
    margin-bottom: ${({ isPack }) => getRelative(isPack ? 42 : 19 , 'mobile')};

    ${mq.greaterThan<any>('tablet')`
      margin-bottom: ${({ isPack }) => isPack ? `${getRelative(10, 'desktop')}` : '0'};
    `}
  }

  .description-clamp {
    ${getP18_3()}
    height: ${getRelative(154, 'mobile')};
    margin: ${getRelative(40, 'mobile')} 0 ${getRelative(60, 'mobile')};
    overflow: hidden;

    ${mq.greaterThan('tablet')`
      ${getP20_1()}
      height: ${getRelative(182, 'desktop')};
      margin: ${getRelative(40, 'desktop')} 0 ${getRelative(80, 'desktop')};
    `}

    button {
      ${getP18_3()}
      color: ${({ theme }) => theme.colors.greenlight};
      transition: 200ms opacity ease-in-out;
      ${createUnderline({ reverse: true, color: 'greenlight' })}

      &:hover {
        opacity: 1;
      }

      ${mq.greaterThan('tablet')`
        ${getP20_1()}
      `}
    }

  }

  .last_units {
    ${getP20_4()}
    color: ${({ theme }) => theme.colors.red};
    display: block;
    margin-bottom: ${getRelative(10, 'mobile')};

    ${mq.greaterThan('tablet')`
      margin-bottom: ${getRelative(10, 'desktop')};
  `}
  }

  .pre_order {
    ${getP16()}
    color: ${({ theme }) => theme.colors.orange};
    display: block;
    margin-bottom: ${getRelative(10, 'mobile')};

    ${mq.greaterThan('tablet')`
      margin-bottom: ${getRelative(10, 'desktop')};
  `}
  }

`

const Brand = styled.span`
  ${getP18_1()}
  margin-bottom: ${getRelative(20, 'mobile')};
  ${createUnderline({ height: 0.5, color: 'greenlight' })}

  ${mq.greaterThan('tablet')`
    ${getP20_1()}
    margin-bottom: ${getRelative(10, 'desktop')};
  `}
`

const ProductInfo = styled(motion.div)`
  font-family: ${({ theme }) => theme.fonts.basier};
  display: flex;
  justify-content: center;
  margin-bottom: ${getRelative(20, 'mobile')};

  ${mq.greaterThan('tablet')`
    margin-bottom: ${getRelative(20, 'desktop')};
  `}

  &.single-variant {
    display: block;
    label {
      ${getP15_1B()}
      font-weight: 600;
    }
  }

  label {
    ${getP12()}
    font-weight: 500;
  }

  div {
    display: flex;
    align-items: center;
    position: relative;
    margin: 0 ${getRelative(25, 'mobile')};

    ${mq.greaterThan('tablet')`
      margin: 0 ${getRelative(25, 'desktop')};
    `}
  }

  input {
    position: absolute;
    margin: 0;
    opacity: 0;
  }

`

const FlexWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  margin: 0 0 ${getRelative(40, 'mobile')};

  ${mq.greaterThan('tablet')`
    margin: 0 0 ${getRelative(40, 'desktop')};
  `}

  button {
    ${getP15_1B()}
  }
  
`

const MediaWrapper = styled.div`
  position: relative;
  margin-bottom: ${getRelative(45, 'mobile')} ;

  ${mq.greaterThan<any>('tablet')`
    margin-bottom: ${getRelative(45, 'desktop')};
  `}
`

const LabelWrapper = styled.div`
  display: flex;
  position: absolute;
  top: ${getRelative(15, 'mobile')};

  ${mq.greaterThan('tablet')`
    top: ${getRelative(15, 'desktop')};
  `}

  span {
    ${getP12_1()}
    text-transform: uppercase;
    z-index: 1;
    background-color: ${({ theme }) => theme.colors.orange};
    color: ${({ theme }) => theme.colors.white};
    margin-right: ${getRelative(5, 'mobile')};
    padding: ${getRelative(5, 'mobile')} ${getRelative(8, 'mobile')};
    border-radius: ${getRelative(12, 'mobile')};

    ${mq.greaterThan('tablet')`
      margin-right: ${getRelative(5, 'desktop')};
      padding: ${getRelative(5, 'desktop')} ${getRelative(8, 'desktop')};
      border-radius: ${getRelative(12, 'desktop')};
    `}
  }

  .discount {
    background-color: ${({ theme }) => theme.colors.red};
  }

  .filter-label {
    background-color: unset;
    padding: 0;

    figure {
      width: ${getRelative(24, 'mobile')};
      height: ${getRelative(24, 'mobile')};

      ${mq.greaterThan<any>('tablet')`
        width: ${getRelative(24, 'desktop')};
        height: ${getRelative(24, 'desktop')};
      `}
    }
  }
`

const getFancyVariants = (variants, currentVariant, setCurrentVariant, locale) => variants.map((variant) => {
  const { name, reference_price, reference_unit } = variant
  const hasPricePerUnit = reference_unit !== null && reference_price > 0

  return {
    variant,
    currentVariantId: currentVariant.bigcommerce_id,
    setCurrentVariant,
    text: `${name}${hasPricePerUnit ? getPricePerUnit(reference_price, reference_unit, locale) : ''}`
  }
})


const calcDiscount = (price, retailPrice) => {
  return Math.round((!!retailPrice && price < retailPrice) ? (retailPrice - price)/retailPrice * 100 : 0)
}

const getLabels = labels => labels.map((label, idx) => <span key={idx}>{label.name}</span>)

const NEXT_LOCALE = process.env.NEXT_PUBLIC_LOCALE
export const DetailHeader = memo<IDetailHeader>(({ data, labels = [], isPack = false, currentVariant, setCurrentVariant, validForCartType = true, preOrderCart = false, preOrderDateMessage = '' }) => {
  const { triggerModal } = useModal(modalNotifyMe)
  const { triggerModal:triggerFrModal } = useModal(modalFrNoCart)

  const { bigcommerce_id, name, brand, variants, description, categories, filters, visible } = data || {}
  const { bigcommerce_id:variant_id, media, name:variant_name, prices, reference_price, reference_unit, stock, stock_warning } = currentVariant
  const { locale } = useRouter() || { locale: NEXT_LOCALE }
  const fancyVariants = useMemo(() => getFancyVariants(variants, currentVariant, setCurrentVariant, locale), [bigcommerce_id, variant_id])
  const { layout } = usei18n()

  const Variants = useMemo(() => fancyVariants?.length <= 2 ? <RadioCheckBoxes data={fancyVariants} /> : <Dropdown className='detail-dropdown' trigger={layout?.product_page?.product_main?.choose_size} options={fancyVariants} />, [fancyVariants])

  const { price_inc_tax, retail_price_inc_tax } = prices || {}
  const discount = useMemo(() => calcDiscount(price_inc_tax, retail_price_inc_tax), [price_inc_tax, retail_price_inc_tax])
  const DiscountLabel = useMemo(() => discount > 0  && locale !== 'fr'? <span key={'discount'} className={'discount'}>{`-${discount}%`}</span> : null, [discount])
  const LabelItems = useMemo(() => getLabels(labels), [labels])
  const preselectFilters = useMemo(() => filters?.filter(item => FILTER_LABELS[item.param] && item?.values?.[0]?.visible), [filters])
  const LabelFilters = useMemo(() => preselectFilters?.length > 0 ? getFilterLabels(preselectFilters) : <></>, [preselectFilters])

  const displayStockWarning = stock >= 1 && stock_warning

  return (
    <>
    
    <Header isPack={isPack}>
      {!isPack &&
        <Brand>
          <Link href={`/search?q=${encodeURI(brand?.name)}&${TRANSLATIONS[locale].brand}=${brand?.slug[locale]}`}><a>{brand?.name}<span></span></a></Link>
        </Brand>
      }
      <h1>{name}</h1>
      <MediaWrapper>
        {(LabelItems.length > 0 || DiscountLabel || LabelFilters?.length > 0) &&
          <LabelWrapper>
            {LabelItems}
            {DiscountLabel}
            {LabelFilters}
          </LabelWrapper>
        }
        <ImageSlider data={media} isPack={isPack} key={variant_id}/>
      </MediaWrapper>
      {(displayStockWarning && locale !== 'fr' || stock === 0 && locale !== 'fr' ) && <span className='last_units'>{stock === 0 ? layout.no_stock : layout.last_units}</span>}
      {preOrderDateMessage && <span className='pre_order'>{preOrderDateMessage}</span>}
      {!isPack &&
      <AnimatePresence exitBeforeEnter>
        <ProductInfo key={fancyVariants?.length} variants={variants} exit='exit' animate='animate' initial='initial' className={fancyVariants?.length === 1 ? 'single-variant' : ''} >
          {fancyVariants?.length === 1 ?
            <label>{variant_name}{getPricePerUnit(reference_price, reference_unit, locale)}</label>
          :
            <>{Variants}</>
          }
        </ProductInfo>
        </AnimatePresence>
      }
      <FlexWrap>
        <Price data={prices}/>
        <AddToCart cartItemData={{ name, brand, categories, variant: currentVariant, visible }} variant_id={variant_id} bigcommerce_id={bigcommerce_id} stock={stock} isPDP={true} visible={visible} validForCartType={validForCartType} preOrderCart={preOrderCart} triggerModal={triggerModal(true, {variant_id: variant_id})} triggerFrModal={triggerFrModal(true)}/>
      </FlexWrap>
      <MaxHeightContainer force_link={true} className='description-clamp' elipsis={layout?.product_page?.product_main?.see_more} data={description} />
    </Header>
    </>
  )
})
