
import { memo } from 'react'
import {AnimatePresence } from 'framer-motion'
import styled from 'styled-components'
import { mq, getRelative, createUnderline, hideScrollbar, getP20_1 } from '@/styles'
import { useModal, useMount, useToast } from '@/hooks'
import { Header, Main, Body } from './Layout'



const BodyStyled = styled(Body)`
  h2 {
    ${getP20_1()}
  }
  a {
    ${createUnderline({ reverse: true, color: 'greenlight' })}
  }
  header {
    .prev {
      align-self: center;

      img {
        width: ${getRelative(6, 'mobile')};
        height: ${getRelative(10, 'mobile')};

        ${mq.greaterThan('tablet')`
          width: ${getRelative(10, 'desktop')};
          height: ${getRelative(11, 'desktop')};
        `}
      }

    }
  }

  main {
    ${hideScrollbar()}
    padding: ${getRelative(40, 'mobile')} ${getRelative(40, 'mobile')} ${getRelative(30, 'mobile')};

    h2 {
      margin-bottom: ${getRelative(30, 'mobile')};
      text-align: left;
    }
   
  }

  ${mq.greaterThan('tablet')`
    width: ${getRelative(550, 'desktop')};
    max-width: unset;

    main {
      padding: ${getRelative(50, 'desktop')} ${getRelative(40, 'desktop')} ${getRelative(30, 'desktop')};

      h2 {
        margin-bottom: ${getRelative(30, 'desktop')};
      }
    }
  `}
`

export const modalFrNoCart = 'modal-fr-no-cart'
export const ModalFrNoCart = memo(() => {
  const { render, close, isActive } = useModal(modalFrNoCart)
  const isMounted = useMount()
  const { Container, toasts } = useToast()

  return isMounted ? render(
    <AnimatePresence exitBeforeEnter>
      <BodyStyled framerKey={isActive} isActive={isActive}>
      <>
        <Header text={'Comment acheter'} onClick={close} />
        <Main>
          <h2>Pour en savoir plus sur où trouver nos délices, écrivez-nous à <a href='mailto:commandes@hellomamma.eu'>commandes@hellomamma.eu <span></span></a>.</h2>
          <h2> Vous êtes un client professionnel ? Rendez-vous sur <a href='https://mammafiore.fr/'>mammafiore.fr <span></span></a>.</h2>
        </Main>
        <Container toasts={toasts} />
      </>
      </BodyStyled>
    </AnimatePresence>
  ) : <></>
})
