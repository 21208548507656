"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Price = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var router_1 = require("next/router");
var styled_components_1 = __importStar(require("styled-components"));
var styles_1 = require("../../styles");
var utils_1 = require("../../utils");
var i18n_1 = require("../../i18n");
var ProductPrice = styled_components_1.default.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  text-align: left;\n\n  &.stacked {\n    padding: 0 ", " ", ";\n\n    ", "\n  }\n\n  &.unstacked {\n    align-items: baseline;\n    display: flex;\n    width: 100%;\n  }\n\n  .last_units {\n    ", "\n    color: ", ";\n    display: block;\n\n    &.discount {\n      display:inline;\n      margin-left: 5px;\n    }\n  }\n"], ["\n  text-align: left;\n\n  &.stacked {\n    padding: 0 ", " ", ";\n\n    ", "\n  }\n\n  &.unstacked {\n    align-items: baseline;\n    display: flex;\n    width: 100%;\n  }\n\n  .last_units {\n    ", "\n    color: ", ";\n    display: block;\n\n    &.discount {\n      display:inline;\n      margin-left: 5px;\n    }\n  }\n"])), (0, styles_1.getRelative)(15, 'mobile'), (0, styles_1.getRelative)(16, 'mobile'), styles_1.mq.greaterThan('tablet')(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      padding: 0 ", " ", ";\n    "], ["\n      padding: 0 ", " ", ";\n    "])), (0, styles_1.getRelative)(20, 'desktop'), (0, styles_1.getRelative)(18, 'desktop')), (0, styles_1.getP12_3)(), function (_a) {
    var theme = _a.theme;
    return theme.colors.red;
});
var P = styled_components_1.default.p(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  color: ", ";\n  font-family: ", ";\n  opacity: ", ";\n  order: ", ";\n  position: relative;\n  width: fit-content;\n\n  &:nth-child(1) {\n    margin-left: ", ";\n\n    ", "\n  }\n\n  .value {\n    ", "\n  }\n\n  .decimals,\n  .units {\n    ", "\n  }\n\n  ", "\n"], ["\n  color: ", ";\n  font-family: ", ";\n  opacity: ", ";\n  order: ", ";\n  position: relative;\n  width: fit-content;\n\n  &:nth-child(1) {\n    margin-left: ", ";\n\n    ", "\n  }\n\n  .value {\n    ", "\n  }\n\n  .decimals,\n  .units {\n    ", "\n  }\n\n  ", "\n"])), function (_a) {
    var theme = _a.theme, hasDiscount = _a.hasDiscount;
    return theme.colors[hasDiscount ? 'orange' : 'greendark'];
}, function (_a) {
    var theme = _a.theme;
    return theme.fonts.platform;
}, function (_a) {
    var oldPrice = _a.oldPrice;
    return oldPrice ? .3 : 1;
}, function (_a) {
    var oldPrice = _a.oldPrice, isPDP = _a.isPDP;
    return isPDP && oldPrice ? 2 : 1;
}, function (_a) {
    var oldPrice = _a.oldPrice, isPDP = _a.isPDP;
    return isPDP && oldPrice ? "".concat((0, styles_1.getRelative)(10, 'mobile')) : 0;
}, styles_1.mq.greaterThan('tablet')(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n      margin-left: ", ";\n    "], ["\n      margin-left: ", ";\n    "])), function (_a) {
    var oldPrice = _a.oldPrice, isPDP = _a.isPDP;
    return isPDP && oldPrice ? "".concat((0, styles_1.getRelative)(10, 'desktop')) : 0;
}), function (_a) {
    var oldPrice = _a.oldPrice, isPDP = _a.isPDP;
    if (oldPrice) {
        return (0, styled_components_1.css)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["", ""], ["", ""])), isPDP ? (0, styles_1.getP30_2)() : (0, styles_1.getP15)());
    }
    else {
        return (0, styled_components_1.css)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["", ""], ["", ""])), isPDP ? (0, styles_1.getP50)() : (0, styles_1.getP26)());
    }
}, function (_a) {
    var oldPrice = _a.oldPrice, isPDP = _a.isPDP;
    if (oldPrice) {
        return (0, styled_components_1.css)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["", ""], ["", ""])), isPDP ? (0, styles_1.getP15)() : (0, styles_1.getP12)());
    }
    else {
        return (0, styled_components_1.css)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["", ""], ["", ""])), isPDP ? (0, styles_1.getP30_2)() : (0, styles_1.getP15)());
    }
}, function (_a) {
    var oldPrice = _a.oldPrice;
    if (oldPrice) {
        return (0, styled_components_1.css)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n      display: inline;\n\n      &:after {\n        background-color: ", ";\n        content: '';\n        height: 1px;\n        left: 50%;\n        position: absolute;\n        transform: translate(-50%, -50%);\n        top: 55%;\n        width: 100%;\n      }\n    "], ["\n      display: inline;\n\n      &:after {\n        background-color: ", ";\n        content: '';\n        height: 1px;\n        left: 50%;\n        position: absolute;\n        transform: translate(-50%, -50%);\n        top: 55%;\n        width: 100%;\n      }\n    "])), function (_a) {
            var theme = _a.theme;
            return theme.colors.greendark;
        });
    }
});
var PriceValue = (0, react_1.memo)(function (_a) {
    var value = _a.value, _b = _a.stacked, stacked = _b === void 0 ? false : _b, _c = _a.hasDiscount, hasDiscount = _c === void 0 ? false : _c, _d = _a.oldPrice, oldPrice = _d === void 0 ? false : _d;
    var layout = (0, i18n_1.usei18n)().layout;
    var locale = (0, router_1.useRouter)().locale;
    return ((0, jsx_runtime_1.jsxs)(P, __assign({ hasDiscount: hasDiscount, oldPrice: oldPrice, isPDP: !stacked }, { children: [locale === 'en' && (0, jsx_runtime_1.jsx)("span", __assign({ className: 'units' }, { children: "\u00A3" })), (0, jsx_runtime_1.jsx)("span", __assign({ className: 'value' }, { children: value[0] })), (0, jsx_runtime_1.jsxs)("span", __assign({ className: 'decimals' }, { children: [locale === 'en' ? '.' : ',', value[1]] })), locale !== 'en' && (0, jsx_runtime_1.jsxs)("span", __assign({ className: 'units' }, { children: ["\u20AC/", layout === null || layout === void 0 ? void 0 : layout.price_unit, "."] }))] })));
});
var ConditionalWrapper = function (_a) {
    var condition = _a.condition, wrapper = _a.wrapper, children = _a.children;
    return condition ? wrapper(children) : children;
};
// ProductCard prices are stacked each other,
// PDP prices are in a single row (unstacked)
var NEXT_LOCALE = process.env.NEXT_PUBLIC_LOCALE;
exports.Price = (0, react_1.memo)(function (_a) {
    var data = _a.data, _b = _a.stacked, stacked = _b === void 0 ? false : _b, stockWarning = _a.stockWarning, stock = _a.stock;
    var locale = (0, router_1.useRouter)().locale;
    if (!data || !(data === null || data === void 0 ? void 0 : data.price_ex_tax))
        return null;
    var layout = (0, i18n_1.usei18n)().layout;
    var price_inc_tax = data.price_inc_tax, retail_price_inc_tax = data.retail_price_inc_tax;
    // SAMPLE price_inc_tax: 6.05, retail_price_inc_tax: 10,
    var hasDiscount = !!retail_price_inc_tax && price_inc_tax < retail_price_inc_tax;
    var price = (0, utils_1.currency)(price_inc_tax).split(',');
    var retailPrice = (0, utils_1.currency)(retail_price_inc_tax).split(',');
    var displayStockWarning = stock >= 1 && stockWarning;
    return ((0, jsx_runtime_1.jsx)(ProductPrice, __assign({ className: "product__price ".concat(stacked ? 'stacked' : 'unstacked') }, { children: (0, jsx_runtime_1.jsxs)(ConditionalWrapper, __assign({ condition: (displayStockWarning && !hasDiscount && locale !== 'fr') || (stock === 0 && locale !== 'fr'), wrapper: function (children) {
                return (0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)("span", __assign({ className: 'last_units' }, { children: stock === 0 ? layout.no_stock : layout.last_units })), children] });
            } }, { children: [(0, jsx_runtime_1.jsx)(PriceValue, { value: hasDiscount ? retailPrice : price, oldPrice: hasDiscount, stacked: stacked }), hasDiscount &&
                    (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: (0, jsx_runtime_1.jsx)(ConditionalWrapper, __assign({ condition: displayStockWarning, wrapper: function (children) {
                                return (0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsxs)("span", __assign({ className: 'last_units discount' }, { children: [layout.last_units, " "] })), children] });
                            } }, { children: (0, jsx_runtime_1.jsx)(PriceValue, { value: price, hasDiscount: true, stacked: stacked }) })) })] })) })));
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
